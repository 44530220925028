import React, { Component } from "react";
import { Col, Container } from "react-bootstrap";
import rating from "../../images/rating.png";
import playStore from "../../images/c2-ps.png";
import appStore from "../../images/c2-as.png";
import sticker1 from "../../images/c4-sticker.png";
import sticker2 from "../../images/c4-sticker2.png";

class C2 extends Component {
  anim() {
    if (window !== undefined) {
      const simpleParallax = require("simple-parallax-js");
      var image = document.getElementsByClassName("sp-thumb1");
      new simpleParallax(image, {
        orientation: "down",
        overflow: true,
        maxTransition: 80,
      });
      var image2 = document.getElementsByClassName("sp-thumb2");
      new simpleParallax(image2, {
        orientation: "left",
        overflow: true,
        maxTransition: 80,
      });
    }
  }
  componentDidMount() {
    this.anim();
  }
  componentDidUpdate() {
    this.anim();
  }
  render() {
    const dowloadInfo = this.props.data;
    if (!dowloadInfo) return <></>;
    return (
      <Container fluid className="c2 c-pad">
        <img
          alt=""
          className="c2-sticker1 sp-thumb1"
          src={sticker1}
          height="332px"
          width="auto"
        />
        <img
          alt=""
          className="c2-sticker2 sp-thumb2 d-none d-md-block"
          src={sticker2}
          height="64px"
          width="auto"
        />
        <div className="row d-flex justify-content-center">
          <div className="col col-md-auto text-center c2-rate col-12" sm="12">
            <img alt="" src={this.props.assets["rating_img"]?.url} height="180px" width="auto" />
          </div>
          <div className="col col-md-auto d-flex flex-column justify-content-center text-center text-md-start">
            <div className="c-h1 c2-h1">{dowloadInfo?.header}</div>
            <div className="c2-h2 mb-4">{dowloadInfo?.subHeader}</div>
            <div className="row d-flex justify-content-md-start justify-content-center">
              <Col className="col-auto d-none d-md-block me-2">
                <a
                  href="https://play.google.com/store/apps/details?id=in.junio"
                  target="_blank"
                >
                  <img alt="" src={playStore} height="64px" width="auto" />
                </a>
              </Col>
              <Col className="col-auto d-none d-md-block">
                <a
                  href="https://apps.apple.com/in/app/junio/id1567740156"
                  target="_blank"
                >
                  <img src={appStore} height="64px" />
                </a>
              </Col>
              <Col
                className="col-auto d-md-none"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=in.junio"
                  target="_blank"
                  style={{ marginBottom: "0.5rem" }}
                >
                  <img alt="" src={playStore} height="42px" width="auto" />
                </a>
                <a
                  href="https://apps.apple.com/in/app/junio/id1567740156"
                  target="_blank"
                >
                  <img src={appStore} height="42px" width="auto" />
                </a>
              </Col>
              <Col className="col-auto d-md-none"></Col>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default C2;
